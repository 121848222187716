import { createGlobalStyle, css } from 'styled-components'
import { globalStyle as designSystemGlobalStyle } from '@therich-team/design-system'

import colors from './colors'
import borders from './borders'
import spacing from './spacing'
import effects, { effectsPreset } from './effects'
import typography, { typographyPreset } from './typography'
import layout, { layoutPreset } from './layout'
import preset from './preset'
import wiswigContent from './wiswig-content'

// createGlobalStyle 바로 스타일 작성하면 eslint를 감지 못해서 css에 작성
const style = css`
  /* variables 만 선언 */
  :root {
    ${colors};
    ${borders};
    ${spacing};
    ${effects};
    ${layout};
    ${typography};

    /** in resize-hook for mobile safari height,
    *** [Usage]
    *** height: 100vh; // Fallback for browsers that do not support Custom Properties 
    *** height: var(--doc-height) 
    **/
    --doc-height: 100%;
  }

  ${layoutPreset};
  ${typographyPreset};
  ${effectsPreset};
  ${preset};
  ${wiswigContent};

  ${designSystemGlobalStyle}

  /* based on design system */
  @font-face {
    font-family: 'Apple SD Gothic Neo';
    font-weight: 700;
    font-display: swap;
    src: local('Apple SD Gothic Neo Bold'), url('/fonts/AppleSDGothicNeoB.woff2') format('woff2');
  }
  @font-face {
    font-family: 'Apple SD Gothic Neo';
    font-weight: 500;
    font-display: swap;
    src: local('Apple SD Gothic Neo Medium'), url('/fonts/AppleSDGothicNeoM.woff2') format('woff2');
  }
  @font-face {
    font-family: 'Apple SD Gothic Neo';
    font-weight: 400;
    font-display: swap;
    src: local('Apple SD Gothic Neo Regular'), url('/fonts/AppleSDGothicNeoR.woff2') format('woff2');
  }

  // 수수료 계산기 폰트
  @font-face {
    font-family: 'Droid-Sans';
    src: url('/fonts/DroidSans.woff2') format('woff2');
    font-weight: 500;
  }

  body {
    font-size: var(--font-size-13);

    * {
      color: var(--color-gray-207);
      line-height: 1.1;
      -webkit-tap-highlight-color: transparent; // 모바일에서 tap 되었을때 highlight disable
    }

    /* Focusing the button with a keyboard will show a dashed black line. */
    a,
    button {
      :focus-visible {
        opacity: 1 !important;
        outline: 2px dashed var(--color-white);
      }
    }

    /* Focusing the button with a mouse, touch, or stylus will show a subtle drop shadow. */
    a,
    button {
      :focus:not(:focus-visible) {
        outline: none;
      }
    }
  }
  body {
    background-color: var(--color-gray-19);
    font-size: var(--font-size-13);
  }
  h1 {
    font-size: var(--font-size-25);
  }
  h2 {
    font-size: var(--font-size-20);
  }

  /* ReactModal */
  .ReactModalPortal {
    position: relative;
    z-index: var(--z-10000001);
  }
  /* modal시 body 스크롤 잠금 */
  .ReactModal__Body--open {
    overflow: hidden;
  }
  .ReactModal__Overlay {
    will-change: opacity, transform;
    opacity: 0;
    transform: translateY(var(--spacing-legacy-10));
    transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out;
  }
  .ReactModal__Overlay--after-open {
    opacity: 1;
    transform: translateY(0);
  }
  .ReactModal__Overlay--before-close {
    opacity: 0;
    transform: translateY(var(--spacing-legacy-10));
  }
  /* ReactModal */

  /* progress bar */
  #nprogress {
    /* Make clicks pass-through */
    pointer-events: none;
  }
  #nprogress .bar {
    background: var(--color-primary);

    position: fixed;
    z-index: var(--z-1000);
    top: 0;
    left: 0;

    width: 100%;
    height: 2px;
  }
  #nprogress .peg {
    /* Fancy blur effect */
    display: block;
    position: absolute;
    right: 0px;
    width: 100px;
    height: 100%;
    box-shadow: 0 0 10px var(--color-primary), 0 0 5px var(--color-primary);
    opacity: 1;

    -webkit-transform: rotate(3deg) translate(0px, -4px);
    -ms-transform: rotate(3deg) translate(0px, -4px);
    transform: rotate(3deg) translate(0px, -4px);
  }
  .nprogress-custom-parent {
    overflow: hidden;
    position: relative;
  }
  .nprogress-custom-parent #nprogress .bar {
    position: absolute;
  }
  /* progress bar */
`

const GlobalStyle = createGlobalStyle`${style}`

export default GlobalStyle
