import { css } from 'styled-components'

import { getSizeBy375 as getSize } from '@/utils/style/size'

const typography = css`
  --font-size-10: 1rem;
  --font-size-11: 1.1rem;
  --font-size-12: 1.2rem;
  --font-size-13: 1.3rem;
  --font-size-14: 1.4rem;
  --font-size-15: 1.5rem;
  --font-size-16: 1.6rem;
  --font-size-17: 1.7rem;
  --font-size-18: 1.8rem;
  --font-size-19: 1.9rem;
  --font-size-20: 2rem;
  --font-size-22: 2.2rem;
  --font-size-24: 2.4rem;
  --font-size-25: 2.5rem;
  --font-size-28: 2.8rem;
  --font-size-30: 3rem;
  --font-size-35: 3.5rem;
  --font-size-45: 4.5rem;

  --font-bold: 700;

  @media (max-width: 767px) {
    --font-size-10: ${getSize(10)};
    --font-size-11: ${getSize(11)};
    --font-size-12: ${getSize(12)};
    --font-size-13: ${getSize(13)};
    --font-size-14: ${getSize(14)};
    --font-size-15: ${getSize(15)};
    --font-size-16: ${getSize(16)};
    --font-size-17: ${getSize(17)};
    --font-size-18: ${getSize(18)};
    --font-size-19: ${getSize(19)};
    --font-size-20: ${getSize(20)};
    --font-size-22: ${getSize(22)};
    --font-size-24: ${getSize(24)};
    --font-size-25: ${getSize(25)};
    --font-size-28: ${getSize(28)};
    --font-size-30: ${getSize(30)};
    --font-size-35: ${getSize(35)};
    --font-size-45: ${getSize(45)};
  }
`

export const typographyPreset = css`
  .--truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .--line-truncate {
    display: -webkit-box;
    overflow: hidden;
    line-height: 1.2;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
  }

  .--text-highlight {
    color: var(--color-primary);
  }

  .--text-highlight-bold {
    color: var(--color-primary);
    font-weight: 700;
  }

  .--text-low-level {
    color: var(--color-gray-114);
    font-size: var(--font-size-13);
  }

  /* Image Replacement */
  .--screen-reader-only {
    position: absolute;
    margin: -1px;
    width: 1px;
    height: 1px;
    padding: 0;
    border: 0;
    white-space: nowrap;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    clip-path: inset(50%);
  }
`

export default typography
