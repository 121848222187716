import { css } from 'styled-components'

const effects = css`
  --shadow-side: -10px 0px 13px -7px #000000, 10px 0px 13px -7px #000000,
    5px 5px 15px 5px rgba(0, 0, 0, 0);

  --shadow-bottom: 0px 0px 20px 0px rgb(0 0 0 / 25%);
  --shadow-normal-blur-3: 0px 1px 3px 0px rgba(0, 0, 0, 0.25);
  --shadow-normal-blur-10: 0px 2px 10px 0px rgba(0, 0, 0, 0.25);
  --shadow-bottom-blur-12: 0px 10px 12px -7px rgba(0, 0, 0, 0.5);
  --shadow-bottom-5-5-10: 5px 5px 10px 0px rgba(0, 0, 0, 0.1);
  --shadow-bottom-0-2-5-8: 0px 2px 5px 8px rgba(0, 0, 0, 0.1);
  --shadow-bottom-blur-all: 2px 2px 10px -1px rgba(0, 0, 0, 0.5);

  --text-shadow: 0px 2px 4px rgba(0, 0, 0, 1);

  --linear-gradient-32-27: linear-gradient(135deg, var(--color-gray-32), var(--color-gray-27));
  --linear-gradient-37-27: linear-gradient(135deg, rgb(37, 45, 65), var(--color-gray-27));
  --linear-gradient-37-27-2: linear-gradient(
    135deg,
    rgb(37, 45, 65, 0.5) 0%,
    var(--color-gray-27) 80%
  );
  --linear-gradient-227-187: linear-gradient(
    90deg,
    rgba(227, 180, 86, 1) -20%,
    rgba(187, 51, 33, 1) 100%
  );
  --linear-gradient-227-187-180d: linear-gradient(
    180deg,
    rgba(227, 180, 86, 1) 0%,
    rgba(202, 44, 31, 1) 80%
  );
  --linear-gradient-227-202: linear-gradient(
    90deg,
    rgba(227, 180, 86, 1) 0%,
    rgba(202, 85, 31, 1) 100%
  );
  --linear-gradient-253-244-180d: linear-gradient(
    180deg,
    rgba(253, 234, 127, 1) 0%,
    rgba(244, 179, 42, 1) 80%
  );
  --linear-gradient-green-blue: linear-gradient(
    90deg,
    rgba(45, 180, 139, 1) 0%,
    rgba(27, 103, 191, 1) 100%
  );
  --linear-gradient-black-blur: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.6) 100%
  );
  --linear-gradient-red-purple: linear-gradient(
    180deg,
    rgba(255, 0, 0, 0.2) 0%,
    rgba(255, 0, 229, 0.2) 100%
  );
  --linear-gradient-badge-activity: linear-gradient(
    122.91deg,
    #ffd9d0 -14.42%,
    #a36050 19.37%,
    #e2a090 65.65%,
    #fbd5cd 96.31%
  );
  --linear-gradient-badge-special: linear-gradient(
    125.78deg,
    #6e89eb -52.85%,
    #9cb0f7 -20.49%,
    #e4eaff -4.04%,
    #6483f7 16.34%,
    #9cb0f7 61.88%,
    #d1dafc 87.88%
  );

  --linear-gradient-badge-bronze-background: linear-gradient(#2a1e1e, #2a1e1e);
  --linear-gradient-badge-bronze-border: linear-gradient(
    20deg,
    #844638,
    #ee9784,
    #f5ded9,
    #e29e8f,
    #844638
  );
  --linear-gradient-badge-silver-background: linear-gradient(#1e222a, #1e222a);
  --linear-gradient-badge-silver-border: linear-gradient(20deg, #858585, #c9c9c9, #f1f1f1, #757575);
  --linear-gradient-badge-gold-background: linear-gradient(#232322, #232322);
  --linear-gradient-badge-gold-border: linear-gradient(20deg, #d2bd41, #f7e99c, #f9f5e2, #d2bd41);
  --linear-gradient-badge-platinum-background: linear-gradient(#182821, #182821);
  --linear-gradient-badge-platinum-border: linear-gradient(
    20deg,
    #41d296,
    #9cf7d1,
    #e2f9ef,
    #41d296
  );
  --linear-gradient-badge-diamond-background: linear-gradient(#18202c, #18202c);
  --linear-gradient-badge-diamond-border: linear-gradient(
    20deg,
    #6e89eb,
    #9cb0f7,
    #e2e7f9,
    #6d8bf8
  );
`

export const effectsPreset = css`
  .--glass-mophism {
    backdrop-filter: blur(10px) saturate(20%);
    background-color: var(--color-gray-19-a75);
    border-radius: var(--border-radius-12);
  }

  .--hover-up-scale-shadow {
    transition: transform 0.2s, box-shadow 0.2s;

    :not(:disabled) {
      @media (hover: hover) and (pointer: fine) {
        :hover {
          transform: scale(1.03);
          box-shadow: var(--shadow-bottom-blur-all);
        }
      }
    }
  }
`

export default effects
